.wrapperContent {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  background-color: #212122;
}

.container {
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

/* Pseudo-element for background image */
.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../../public/assets/images/95adafe97d0f31da25545197c88668ec.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(5px);
  z-index: 0;
  box-shadow: 0px 0px 15px #212122;
}

.backgroundImage {
  height: 100%;
  width: 100%;
  position: relative;
}

.overlay {
  height: 100%;
  width: 100%;
  display: grid;
  position: relative;
  z-index: 2;
  color: white;
  grid-template-rows: 35% 40% 20%;
  grid-template-columns: 100%;
  justify-items: center;
  align-items: center;
}

.overlayLogin {
  height: 100%;
  width: 100%;
  display: grid;
  position: relative;
  z-index: 2;
  color: white;
  grid-template-rows: 35% 30% 5% 20%;
  grid-template-columns: 100%;
  justify-items: center;
  align-items: center;
}

.overlayPublic {
  height: 100%;
  width: 100%;
  display: grid;
  position: relative;
  z-index: 2;
  color: white;
  grid-template-rows: 35% 65%;
  grid-template-columns: 100%;
  justify-items: center;
  align-items: center;
}

.overlayContent {
  display: grid;
  width: 100%;
  position: relative;
  justify-items: center;
  gap: 30px;
}

.overlayButtons {
  display: flex;
  gap: 30px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 400px;
  height: auto;
  margin-bottom: 20px;
  cursor: pointer;
}

.createAccount {
  position: relative;
  background: linear-gradient(0deg, rgba(254, 169, 56, 0.4) 0%, #ea6309 50%),
    linear-gradient(180deg, #fea938 0%, rgba(234, 99, 9, 1) 60%);
  border-radius: 25px;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  max-width: 305px;
  height: 50px;
  overflow: hidden;
  transition: color 0.3s ease;
}

.createAccount::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2); /* Adjust the dark overlay color */
  transition: opacity 0.3s ease;
  opacity: 0;
}

.createAccount:disabled {
  background: linear-gradient(
      0deg,
      rgba(254, 169, 56, 0.2) 0%,
      rgba(234, 99, 9, 0.3) 50%
    ),
    linear-gradient(
      180deg,
      rgba(254, 169, 56, 0.2) 0%,
      rgba(234, 99, 9, 0.4) 60%
    );
}

.createAccount:hover::after {
  opacity: 1; /* Overlay becomes visible on hover */
}

.alreadyAccount {
  font-size: 16px;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  padding: 0 15px;
  border-radius: 25px;
  max-width: 225px;
  height: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}

.alreadyAccount:hover {
  background-color: white;
  color: #212122;
  border-color: #fea938;
}

.forgotPassword {
  font-size: 12px;
  background-color: transparent;
  color: white;
  border: 1px solid transparent;
  padding: 0 15px;
  border-radius: 25px;
  max-width: 225px;
  height: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}

.forgotPassword:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .container::before {
    background-image: url("../../../public/assets/images/login.png");
    background-size: contain;
    filter: unset;
  }

  .backgroundImage {
    background-image: url("../../../public/assets/images/background-reg-login.d5078b7c.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .logo {
    width: 200px;
  }
}
