.secureWallpaper {
   width: 100%;
   position: relative;
   height: 100%;
   margin: 0;
   padding: 0;
   display: block;
   background: radial-gradient(64.1% 39.78% at 48% 52.59%, rgba(254, 169, 56, 0.5) 0%, #212122 100%),
      url('../../../public/assets/images/b11a999e9478320f86696fb06fcf21a3.jpeg');
   background-repeat: no-repeat;
   background-size: cover;
   background-color: #151516;
}

.secureWrapper {
   display: grid;
   grid-template-rows: calc(100% - 70px) 70px;
   height: 100%;
   width: 100%;
   background-color: #151516;
}

.matchesWrapper {
   display: grid;
   height: 100%;
   width: 100%;
   position: relative;
   grid-template-rows: 75px auto;

   .favoritesWrapper {
      height: 100%;
      width: 100%;
      position: relative;
      overflow-y: auto;
      gap: 15px;
      min-height: 100px;
      // overflow-x: hidden;
   }
}

.favoriteColleapseLeagueCard {
   width: 100%;
   display: grid;
   align-items: center;
   align-content: center;
   position: relative;
   background: transparent;
   min-height: 100px;
   cursor: pointer;

   * {
      user-select: none;
   }

   .matchCardHeader {
      width: 100%;
      border-radius: 8px;
      min-height: 100px;
      margin: 5px 0;
      grid-template-columns: 100%;
      box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.8); /* Customize the color and spread */
      display: grid;
      align-items: center;
      position: relative;
   }

   .leagueCardHeader {
      width: 100%;
      border-radius: 8px;
      min-height: 100px;
      margin: 5px 0;
      grid-template-columns: 100px 200px 100px auto;
      box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.8); /* Customize the color and spread */
      display: grid;
      align-items: center;
      position: relative;
   }

   .favoriteCollapseLeagueCardWidgetInformation {
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      gap: 5px;
      align-items: center;
   }

   .favoriteCollapseLeagueCardWidgetCountry {
      color: #fff;
      font-size: 15px;
      font-weight: 600;
   }

   .favoriteCollapseLeagueCardWidgetFixtures {
      color: #999;
      font-size: 11px;
      font-weight: 600;
   }

   img {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      position: relative;
      object-fit: cover;
      object-position: center;
      background-color: white;
   }
}

.myLeaguesWrapper {
   display: grid;
   grid-template-rows: 50px 30px auto;
   position: relative;
   gap: 10px;
   height: 100%;
   width: 100%;

   .wallContentWrapper {
      .wallContentTabs {
      }

      .wallContent {
      }
   }

   .myLeaguesLogo {
      height: auto;
      cursor: pointer;
      margin: 0 auto;
   }

   .myLeaguesWelcome {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      align-self: start;
      color: #fff;
   }

   .myLeaguesSelectLeagues {
      font-size: 30px;
      font-weight: 500;
      text-align: center;
      color: #fff;
      max-width: 350px;
      align-self: start;
   }

   .myLeaguesSelectLeagues:hover {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 3px;
      text-decoration-color: white;
   }
}

.headerWrapper {
   width: 100%;
   height: 100%;
   min-height: 75px;
   max-height: 75px;
   display: grid;
   grid-template-columns: 50px auto 50px;
   align-items: center;
   align-content: center;
   z-index: 1;
   overflow: hidden;

   .headerTitle {
      font-size: 30px;
      text-align: center;
      color: #fff;
      letter-spacing: 2px;
      font-weight: 650;
      font-style: italic;
   }
}

.footerWrapper {
   width: 100%;
   height: 100%;
   max-height: 70px;
   min-height: 70px;
   display: grid;
   background-color: #2b2b2b;
   grid-template-columns: repeat(4, 1fr);
   overflow: hidden;
   z-index: 1;

   .footerButton {
      display: flex;
      flex-direction: column;
      padding: 0 !important;
      width: 100%;
      position: relative;

      svg {
         font-size: 30px;
      }

      * {
         font-size: 14px;
      }
   }

   .footerButtonActive {
      background: linear-gradient(180deg, #ee843a 0%, #ffab3a 100%);
      border-radius: 0 10px 0 0;
      height: 70px;
   }
}

.hideSearchBar {
   grid-template-rows: auto !important;
}

.browseWrapper {
   width: 100%;
   height: 100%;
   overflow: auto;
   display: grid;
   position: relative;
   grid-template-rows: 50px auto;

   .countriesListingWrapper {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
   }

   .browseCountriesListing {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      z-index: 0;
      overflow-y: auto;
      padding: 10px 0;

      .listingNotFound {
         display: grid;
         justify-content: center;
         align-items: center;
         width: 100%;
         height: 100%;
         font-size: 22px;
         font-weight: 600;
         color: #fff;
      }

      .browseLeagueListingWidget {
         grid-template-columns: 100px auto 100px !important;
      }

      .browseCountryListingWidget {
         width: auto;
         display: grid;
         grid-template-columns: 100px auto;
         align-items: center;
         align-content: center;
         position: relative;
         padding: 10px;
         min-height: 100px;
         max-height: 100px;

         .browseCountryListingWidgetFlag {
            width: 75px;
            height: 75px;
            border-radius: 50%;
            position: relative;
            object-fit: cover;
            object-position: center;
            background-color: white;
         }

         .browseCountryListingWidgetInformation {
            display: grid;
            grid-template-rows: repeat(3, 1fr);
            gap: 5px;
            align-items: center;

            .browseCountryListingWidgetLeagues {
               color: #999;
               font-size: 11px;
               font-weight: 600;
            }

            .browseCountryListingWidgetCountry {
               color: #fff;
               font-size: 15px;
               font-weight: 600;
            }

            .browseCountryListingWidgetFixtures {
               color: #999;
               font-size: 11px;
               font-weight: 600;
            }
         }
      }

      .browseCountryListingWidget:hover {
         background: #1f2022;
         cursor: pointer;
      }
   }
}

.fixturesBidWrapper {
   width: 100%;
   padding: 10px;
   position: relative;
   min-height: 125px;
   // max-height: 125px;
   background: linear-gradient(180deg, #ee843a 0%, #ffab3a 100%);
   display: flex;
   flex-direction: column;
   box-sizing: border-box;
   cursor: pointer;

   .fixturesBidWrapperMakeABid {
      width: 100%;
      height: 100%;
      position: relative;
      font-size: 22px;
      font-weight: 600;
      color: #fff;
      letter-spacing: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
   }

   .fixturesBidWrapperMakeABid:hover {
      cursor: pointer;
   }

   .fixturesBidWrapperInfo {
      width: 100%;
      height: 100%;
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      justify-content: center;

      .fixturesBidWrapperInfoTeams {
         width: 100%;
         height: 100%;
         position: relative;
         display: flex;
         align-items: center;
         justify-content: center;
         gap: 10px;

         .fixturesBidWrapperInfoTeamsName {
            font-size: 22px;
            // text-align: center;
            color: #fff;
            max-width: 200px;
         }

         img {
            width: 70px;
            height: 70px;
            object-fit: contain;
         }
      }

      .fixturesBidWrapperInfoTime {
         width: 100%;
         height: 100%;
         text-align: center;
         position: relative;
         color: #fff;
         font-size: 16px;
         display: flex;
         justify-content: center;
         align-items: center;
         letter-spacing: 2px;
      }
   }
}

.customTabs {
   width: 100%;
   position: relative;
   height: 100%;
   display: grid;
   justify-content: center;
   align-items: center;
}

.fixturesList {
   // max-height: 500px;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
   z-index: 2;
   overflow: auto;
   width: 100%;
   // padding: 20px 0 20px 0;
   background: #292929;
   border-radius: 15px;
   box-sizing: border-box;
}

.bidUsername {
   font-size: 16px;
   color: #fff;
   letter-spacing: 0px;
}

.fixturesFinishedWrapper {
   width: 100%;
   padding: 20px;
   position: relative;
   // min-height: 135px;
   // background: #151516;
   // border-bottom: 1px solid #2a2a2b;
   box-shadow: 0 8px 10px -8px rgba(0, 0, 0, 0.8);
   flex-direction: column;
   display: flex;
   box-sizing: border-box;
   cursor: pointer;
   gap: 10px;

   .fixturesFinishedWrapperInfo {
      width: 100%;
      height: 100%;
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      justify-content: center;

      .fixturesFinishedWrapperInfoTeams {
         width: 100%;
         height: 100%;
         position: relative;
         display: flex;
         align-items: center;
         justify-content: center;
         gap: 10px;

         .fixturesFinishedWrapperInfoTeamsName {
            font-size: 22px;
            // text-align: center;
            color: #fff;
            max-width: 200px;
         }

         img {
            width: 70px;
            height: 70px;
            object-fit: contain;
         }
      }

      .fixturesBidWrapperInfoTime {
         width: 100%;
         height: 100%;
         text-align: center;
         position: relative;
         color: #fff;
         font-size: 16px;
         display: flex;
         justify-content: center;
         align-items: center;
         letter-spacing: 2px;
      }

      .fixturesFinishedWrapperInfoScore {
         width: 100%;
         height: 100%;
         text-align: center;
         position: relative;
         color: #fff;
         font-size: 30px;
         display: flex;
         justify-content: center;
         align-items: center;
         letter-spacing: 10px;
         font-weight: 700;
      }
   }

   .fixturesFinishedWrapperBids {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      width: 50%;
      height: 100%;

      .fixturesFinishedWrapperBid {
         width: 100%;
         max-width: 200px;
         height: 100%;
         max-height: 30px;
         min-height: 30px;
         border-radius: 20px;
         display: grid;
         grid-auto-flow: column;
         align-content: center;
         align-items: center;

         .fixturesFinishedWrapperBidLabel {
            color: #999;
            font-size: 14px;
            text-transform: capitalize;
            font-weight: 600;
            margin-left: 15%;
         }

         .fixturesFinishedWrapperBidQuote {
            color: #fff;
            font-size: 14px;
            text-transform: capitalize;
            font-weight: 600;
            justify-self: end;
            margin-right: 15%;
         }
      }

      .fixturesFinishedWrapperSelected {
         border: 1px solid #47b0e3 !important;
         background: #122f3f !important;
      }

      .fixturesFinishedWrapperWon {
         border: 1px solid #43ba58 !important;
         background: #2d5335 !important;
      }

      .fixturesFinishedWrapperLost {
         background: #6c2b0f !important;
      }
   }
}

.fixturesLiveWrapper {
   // border: 1px solid #2a2a2b !important;
   background: #1f2022 !important;
}

.fixturesLiveSymbolWrapper {
   position: relative;
   width: 100%;
   grid-auto-flow: row;
   place-items: center;
   display: grid;
   place-self: center;
}

.fixturesLiveSymbol {
   font-size: 16px;
   color: #ffac3a;
}

.fixturesLiveElapsed {
   font-size: 16px;
   color: #ffac3a;
}

.fixtureWrapper {
   width: 100%;
   height: 100%;
   overflow: auto;
   position: relative;
   display: grid;
   grid-template-rows: 30% 60px max-content 60px;
   background: radial-gradient(60% 85% at 50% 10%, rgba(254, 169, 56, 0.5) 0%, #151516 100%),
      url('../../../public/assets/images/95adafe97d0f31da25545197c88668ec.jpeg');
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;

   .fixtureInformationWrapper {
      width: 100%;
      height: 100%;
      position: relative;
      display: grid;
      grid-template-rows: 20% 80%;
      place-content: center;
      padding: 10px 0;

      .fixtureInformationTime {
         width: 100%;
         height: 100%;
         position: relative;
         text-align: center;
         display: grid;
         place-content: center;
         font-size: 22px;
         color: #fff;
      }

      .fixtureInformationTeams {
         width: 100%;
         height: 100%;
         position: relative;
         display: grid;
         grid-template-columns: repeat(3, 1fr);
         place-items: center;
         gap: 50px;

         .fixtureInformationScore {
            font-size: 30px;
            font-weight: 1000;
            color: #fff;
            letter-spacing: 10px;
            display: grid;
            place-content: center;
            place-items: center;
            gap: 10px;

            .fixtureInformationScoreHalftime {
               font-size: 22px;
               letter-spacing: 4px;
               width: 100%;
               text-align: center;
            }

            .fixtureInformationLive {
               width: 100%;
               max-height: 50px;
               border-radius: 20px;
               background: linear-gradient(180deg, #ffac3a 0%, #eb670c 100%), #ed8035;
               font-size: 14px;
               color: #fff;
               text-align: center;
               letter-spacing: 5px;
            }

            .fixtureInformationElapsed {
               width: 100%;
               color: rgba(255, 172, 58, 1);
               font-size: 18px;
               letter-spacing: 3px;
               text-align: center;
               font-weight: 600;
            }
         }

         .fixtureInformationTeam {
            display: grid;
            width: 100%;
            position: relative;
            height: 100%;
            grid-template-rows: 80% 20%;
            place-items: center;

            .fixtureInformationTeamFlag {
               width: 150px;
               height: 150px;
               object-fit: contain;
            }

            .fixtureInformationTeamName {
               text-align: center;
               font-size: 22px;
               color: #fff;
            }
         }
      }
   }

   .fixtureMatchDetailsWrapper {
      position: relative;
      width: 95%;
      display: flex;
      place-content: center;
      place-items: center;
      margin: 0 auto;

      .fixtureMatchDetailsText {
         position: relative;
         font-size: 16px;
         color: rgba(255, 172, 58, 1);
         font-weight: 500;
         padding: 5px 15px;
         border: 1px solid rgba(255, 172, 58, 1);
         border-radius: 20px;
         background-color: #1f2022;
         display: inline-block;
         z-index: 1;
      }
   }

   .fixtureMatchDetailsWrapper::before,
   .fixtureMatchDetailsWrapper::after {
      content: '';
      position: absolute;
      top: 50%;
      width: 100%;
      height: 2px;
      background-color: rgba(255, 172, 58, 1);
   }

   .fixtureMatchDetailsWrapper::before {
      left: 0;
   }

   .fixtureMatchDetailsWrapper::after {
      right: 0;
   }

   .fixtureBiddingInformationWrapper {
      width: 100%;
      position: relative;
      height: auto;
      display: grid;
      grid-template-rows: auto;
      gap: 10px;

      .fixtureBiddingInformationSection {
         width: 100%;
         position: relative;
         height: auto;
         display: grid;
         grid-template-rows: auto;
         place-items: center;
         place-content: center;
         gap: 10px;

         .fixtureBiddingInformationSectionTitle {
            color: cornsilk;
            font-size: 16px;
            font-weight: 500;
         }

         .fixtureBiddingInformationSectionContent {
            width: 100%;
            position: relative;
            height: auto;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 35px;
            row-gap: 20px;
            min-width: 600px;

            .fixtureBiddingInformationSectionCard:hover {
               background-color: rgba(255, 172, 58, 1);
               border: 1px solid transparent;
               transition: background-color 0.2s linear;

               * {
                  color: white !important;
               }
            }

            .fixtureBiddingInformationSectionCardDisabled {
               color: #888;
               opacity: 0.7;
               cursor: not-allowed !important;
            }

            .fixtureBiddingInformationSectionCardDisabled:hover {
               background: #212122 !important;
               opacity: 0.7;
               border: 1px solid #2a2a2b;
            }

            .fixtureBiddingInformationSectionCardActive {
               border: 1px solid #47b0e3 !important;
               background: #122f3f !important;
            }

            .fixtureBiddingInformationSectionCardWon {
               border: 1px solid #43ba58 !important;
               background: #2d5335 !important;
            }

            .fixtureBiddingInformationSectionCardLost {
               background: #6c2b0f !important;
               border: unset !important;
            }

            .fixtureBiddingInformationSectionCardCorrect {
               border: 1px solid #43ba58 !important;
            }

            .fixtureBiddingInformationSectionCard {
               width: 100%;
               position: relative;
               min-width: 105px;
               height: auto;
               min-height: 35px;
               border-radius: 20px;
               border: 1px solid #2a2a2b;
               background: #212122;
               display: grid;
               grid-template-columns: 40% 50%;
               justify-items: end;
               align-items: center;
               cursor: pointer;

               .fixtureBiddingInformationSectionCardTitle {
                  font-size: 12px;
                  color: rgba(153, 153, 153, 1);
                  text-transform: capitalize;
                  justify-self: start;
                  margin-left: 15%;
               }

               .fixtureBiddingInformationSectionCardQuote {
                  font-size: 16px;
                  color: #fff;
                  justify-self: end;
               }
            }
         }
      }
   }

   .fixtureBiddingSaveButton {
      position: relative;
      display: block;
      width: 100%;
      height: 35px;
      max-height: 35px;
      min-height: 35px;
      height: auto;
      background: rgba(255, 172, 58, 1);
      color: white;
      max-width: 200px;
      min-width: 200px;
      border-radius: 20px;
      margin: 15px auto 0 auto;
      align-self: center;
      cursor: pointer;
      border: 1px solid transparent;
      font-size: 16px;
      letter-spacing: 10px;
      font-weight: 600;
      text-transform: uppercase;
   }

   .fixtureBiddingSaveButton:hover {
      background-color: #212122;
      transition: background-color 0.1s linear;
      border: 1px rgba(255, 172, 58, 1) solid;
   }
}

.profileWrapper {
   width: 100%;
   position: relative;
   height: 100%;
   overflow: hidden;
   padding: 0;
   margin: 0;
   display: grid;
   justify-items: center;

   .profileHeader {
      width: 95%;
      position: relative;
      height: 100%;
      display: grid;
      grid-template-columns: 50px auto 50px;
      align-items: center;

      .profileHeaderIcons {
         color: #fff;

         svg {
            font-size: 30px;
         }
      }

      .profileHeaderUsername {
         color: #fff;
         text-align: center;
         font-size: 16px;
      }
   }

   .profileContent {
      width: 100%;
      position: relative;
      height: 100%;
      display: grid;
      grid-template-rows: 150px auto;
      place-items: center;

      .profileContentPicture {
         width: 100%;
         height: 100%;
         max-height: 150px;
         max-width: 150px;
         border-radius: 50%;
         background-image: url('../../../public/assets/images/profile_picture.jpg');
         background-position: center;
         background-size: contain;
         background-repeat: no-repeat;
      }

      .profileContentSection {
         width: 100%;
         position: relative;
         height: 100%;
         display: grid;
         margin-top: 25px;
         grid-template-rows: 30px auto;
         gap: 10px;

         .profileContentTabs {
            display: grid;
            position: relative;
            height: 100%;
            max-height: 30px;
            width: 40%;
            place-self: center;
            grid-template-columns: 1fr 1px 1fr;
            place-items: center;

            .profileContentTab {
               .profileContentTabText {
                  text-align: center;
                  color: #999;
                  font-size: 20px;
                  cursor: pointer;
                  width: auto;
                  height: 100%;
               }
            }

            .profileContentTabActive {
               * {
                  color: #fff !important;
               }
            }

            .profileContentTabSeparator {
               width: 2px;
               background-color: #2a2a2b;
               height: 20px;
            }
         }

         .profileContentMyStatistics {
            width: 40%;
            height: 90%;
            position: relative;
            display: grid;
            grid-template-rows: repeat(4, 1fr);
            place-items: center;
            margin-top: 20px;
            place-self: center;

            .profileContentMyStatisticsCard {
               width: 100%;
               height: 100%;
               position: relative;
               grid-template-rows: 30px auto;
               display: grid;
               place-items: center;

               .profileContentMyStatisticsCardTitle {
                  font-size: 14px;
                  color: #999;
                  text-decoration: underline;
                  text-decoration-thickness: 0.5px;
                  text-underline-offset: 10px;
                  text-decoration-color: #999;
                  letter-spacing: 1px;
               }

               .profileContentMyStatisticsCardInformation {
                  display: grid;
                  grid-template-columns: repeat(3, 1fr);
                  width: 100%;
                  position: relative;
                  height: 100%;
                  place-items: center;

                  .profileContentMyStatisticsCardInformationWidget {
                     .profileContentMyStatisticsCardLabel {
                        font-size: 16px;
                        color: #999;
                        text-align: center;
                     }

                     .profileContentMyStatisticsCardValue {
                        font-size: 36px;
                        color: #fff;
                        text-align: center;
                     }
                  }
               }
            }
         }
      }
   }
}

.profileSettingsWrapper {
   width: 100%;
   position: relative;
   display: grid;
   place-items: center;

   .profileSettingsContent {
      width: 35%;
      position: relative;
      display: grid;
      height: auto;
      gap: 75px;

      .profileSettingsContentRow {
         width: 100%;
         position: relative;
         grid-template-columns: 80% 20%;
         display: grid;
         max-height: 30px;
         align-items: center;
         padding: 4px 0 8px;
         border-bottom: 1px solid #444;

         .profileSettingsContentRowPublicProfileLabel {
            color: #fff;
            font-size: 16px;
            font-weight: 600;
         }

         .profileSettingsContentRowPublicProfileButton {
            justify-self: end;
         }

         .profileSettingsContentRowInfo {
            display: flex;
            flex-direction: column;
            gap: 3px;

            .profileSettingsContentRowInfoLabel {
               color: #999;
               font-size: 14px;
            }

            .profileSettingsContentRowInfoValue {
               color: #fff;
               font-size: 16px;
            }
         }

         .profileSettingsContentRowEdit {
            color: rgba(186, 67, 67, 1);
            font-size: 20px;
            justify-self: end;

            * {
               color: rgba(186, 67, 67, 1);
               font-size: 20px;
            }
         }
      }

      .profileSettingsButtons {
         font-size: 18px;
         font-weight: 600;
         letter-spacing: 1px;
         display: flex;
         flex-direction: column;
         justify-content: start;
         align-items: center;
         gap: 35px;

         .profileSettingsSaveChanges {
            color: #43ba58;
         }

         .profileSettingsSaveChanges:hover {
            opacity: 0.7;
         }

         .profileSettingsLogOut {
            color: rgba(186, 67, 67, 1);
         }

         .profileSettingsLogOut:hover {
            opacity: 0.7;
         }
      }
   }
}

.userListingNotFound {
   display: grid;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;
   font-size: 22px;
   font-weight: 600;
   color: #fff;
}

.searchUsersWrapper {
   display: grid;
   width: 95%;
   border-radius: 15px;
   background-color: #2b2b2b;
   height: 90%;
   position: relative;
   place-items: center;
   place-self: center;
   padding: 20px;

   .searchUsersContent {
      width: 100%;
      position: relative;
      grid-template-rows: 7.5% 83% 10%;
      height: 100%;
      gap: 15px;
      display: grid;
      overflow: hidden;

      .searchUsersPagination {
         * {
            color: rgb(170, 170, 170) !important;
         }
      }

      .searchUsersListing {
         width: 100%;
         position: relative;
         display: flex;
         flex-direction: column;
         max-height: 100%;
         gap: 15px;
         padding: 0 10px;
         overflow: auto;

         .searchUsersWidget {
            width: 100%;
            position: relative;
            height: 100%;
            display: grid;
            gap: 20px;
            grid-template-columns: 100px 150px auto;
            align-items: center;
            justify-content: start;
            max-height: 100px;

            .searchUsersPhoto {
               max-height: 100px;
               max-width: 100px;
               border-radius: 50%;
               cursor: pointer;
            }

            .searchUsersFollowYou {
               padding: 5px 12px;
               background: linear-gradient(180deg, #ee843a 0%, #ffab3a 100%);
               border-radius: 10px;
               letter-spacing: 2px;
               font-size: 12px;
               font-weight: 800;
               color: white;
               text-transform: uppercase;
            }

            .searchUsersInformation {
               width: 100%;
               position: relative;
               display: grid;
               grid-template-rows: repeat(3, 1fr);
               gap: 10px;
               align-items: center;

               .searchUsersInformationSince {
                  color: #999;
                  font-weight: 600;
                  font-size: 12px;
               }

               .searchUsersInformationName {
                  color: #fff;
                  font-weight: 600;
                  font-size: 16px;
               }

               .searchUsersInformationCountry {
                  color: #999;
                  font-weight: 600;
                  font-size: 12px;
               }
            }
         }
      }
   }
}

.profileContentLeaguesWrapper {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   position: relative;

   .profileContentLeaguesWidget:hover {
      background: #1f2022;
   }

   .profileContentLeaguesWidget {
      width: 100%;
      height: auto;
      position: relative;
      display: grid;
      grid-template-columns: 100px auto;
      padding: 15px 10px;
      cursor: pointer;

      .profileContentLeaguesWidgetFlag {
         width: 75px;
         height: 75px;
         border-radius: 50%;
         position: relative;
         object-fit: cover;
         object-position: center;
         background-color: white;
      }

      .profileContentLeaguesWidgetInformation {
         display: grid;
         grid-template-rows: repeat(3, 1fr);
         gap: 5px;
         align-items: center;

         .profileContentLeaguesWidgetSuccessRate {
            font-size: 15px;
            color: #fff;

            span {
               font-size: 11px;
               color: #999;
            }
         }

         .profileContentLeaguesWidgetName {
            font-size: 15px;
            color: #fff;
         }

         .profileContentLeaguesWidgetBets {
            font-size: 11px;
            color: #999;
         }
      }
   }
}

.fixturesFinishedWrapperWall {
   border-radius: 15px;
   width: 100%;
   justify-self: center;
   border: 1px solid #47b0e3;
   background-color: #122f3f;
   gap: 10px;
   display: grid;
   grid-template-columns: 160px auto;
   align-items: center;
   padding: 10px;
}

@media only screen and (max-width: 600px) {
   .myLeaguesLogo {
      width: 200px !important;
   }

   .fixtureWrapper {
      background: radial-gradient(75% 60% at 50% 40%, rgba(254, 169, 56, 0.5) 0%, #212122 100%),
         url('../../../public/assets/images/95adafe97d0f31da25545197c88668ec.jpeg') !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: center !important;
   }

   .fixturesBidWrapper {
      min-height: 100px !important;
   }

   .fixturesFinishedWrapper {
      // min-height: 100px !important;
   }

   .fixturesFinishedWrapperInfoScore {
      font-size: 22px !important;
      letter-spacing: 5px !important;
   }

   .fixturesBidWrapperInfoTime {
      font-size: 12px !important;
   }

   .fixturesBidWrapperMakeABid {
      font-size: 14px !important;
      letter-spacing: 10px !important;
      display: flex;
      justify-content: center;
      align-items: center;
   }

   .fixturesBidWrapperInfoTeams {
      img {
         width: 45px !important;
         height: 45px !important;
      }

      .fixturesBidWrapperInfoTeamsName {
         font-size: 14px !important;
      }
   }

   .fixturesFinishedWrapperInfoTeams {
      img {
         width: 45px !important;
         height: 45px !important;
      }

      .fixturesFinishedWrapperInfoTeamsName {
         font-size: 14px !important;
      }
   }

   .fixtureInformationTeamFlag {
      width: 75px !important;
      height: 75px !important;
   }

   .fixtureInformationTeams {
      gap: 0px !important;
   }

   .fixtureBiddingInformationWrapper {
      width: 90% !important;
      justify-self: center !important;
      place-content: center !important;
   }

   .fixtureBiddingInformationSection {
      place-content: unset !important;
   }

   .fixtureBiddingInformationSectionContent {
      min-width: unset !important;
      column-gap: 10px !important;
   }

   .fixtureInformationTime {
      font-size: 16px !important;
   }

   .fixtureInformationTeam {
      height: auto !important;
   }

   .fixtureInformationTeamName {
      font-size: 16px !important;
   }

   .fixtureWrapper {
      grid-template-rows: 25% 60px max-content 60px !important;
   }

   .fixturesFinishedWrapperBids {
      width: 100% !important;
      gap: 10px;

      .fixturesFinishedWrapperBid {
         grid-template-columns: unset !important;
         grid-auto-flow: column;
      }

      .fixturesFinishedWrapperBidLabel {
         font-size: 10px !important;
         text-transform: capitalize !important;
         margin-left: 15% !important;
      }

      .fixturesFinishedWrapperBidQuote {
         font-size: 12px !important;
         margin-right: 20% !important;
      }
   }

   .profileContentSection {
      width: 100% !important;
   }

   .profileSettingsContent {
      width: 85% !important;
   }

   .searchUsersContent {
      width: 85% !important;
      grid-template-rows: 10% 80% 10% !important;
   }

   .searchUsersWidget {
      gap: 20px !important;

      img {
         max-width: 60px !important;
         max-height: 60px !important;
      }
   }

   .searchUsersSearch {
      max-width: 100% !important;
   }

   .profileContentMyStatistics {
      width: 100% !important;
   }

   .profileContentTabs {
      width: 100% !important;
   }

   .profileContentMyStatisticsCardValue {
      font-size: 28px !important;
   }

   .profileContentPicture {
      width: 125px !important;
      height: 125px !important;
   }

   .leagueCardHeader {
      grid-template-columns: 100px 100px 75px auto !important;
   }

   .searchUsersWrapper {
      width: 100% !important;
   }

   .bidUsername {
      font-size: 12px !important;
      text-overflow: ellipsis;
   }

   .fixturesFinishedWrapperWall {
      grid-template-columns: 80px auto !important;
   }
}
