html,
body,
#root {
   margin: 0;
   padding: 0;
   height: 100%;
   width: 100%;
   overflow: hidden;
   background-color: #151516;
   font-family: 'DM Sans', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   box-sizing: border-box;
}

* {
   font-family: 'DM Sans', sans-serif !important;
}

.appLoader {
   display: grid;
   justify-items: center;
   align-items: center;
   background-color: transparent !important;
   width: 100%;
   height: 100%;
   position: absolute;
   z-index: 100;

   img {
      width: 80%;
      max-width: 400px;
   }
}

/* General scrollbar styles */
::-webkit-scrollbar {
   width: 10px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
   background: #333; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
   background: #555; /* Color of the scrollbar thumb */
   border-radius: 5px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
   background: #777; /* Lighter color when hovered */
}

/* Styling for Firefox */
* {
   scrollbar-width: 2px;
   scrollbar-color: #555 #333;
}

.MuiAlert-filledInfo {
   border: 1px solid #43ba58 !important;
   background: #1a281d !important;
   border-radius: 20px !important;
}

.MuiAlert-filledError {
   border-radius: 20px !important;
   border: 1px solid #ba4343 !important;
   background: #31190f !important;
}

.MuiAlert-message {
   font-size: 16px;
   font-weight: 650;
   font-style: italic;
}
